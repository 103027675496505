<template>
  <div id='searchInfo'>
    <div class="nav">
      <!-- 跨境好服务    臻品永不停 -->
      <van-search v-model="params.keywords" :placeholder="`${language.l_1} ${language.l_2}`" shape="round" show-action>
        <template v-if="name" v-slot:label><p class="leftText van-ellipsis">{{ name }}</p></template>
        <!-- 搜索 -->
        <template v-slot:action><p @click="onSearch">{{language.l_229}}</p></template>
      </van-search>
    </div>
    <div class="reorder van-hairline--top-bottom">
      <!-- 综合 -->
      <div @click="selectReorder(1)" :class="{'reorder-active': reorder == 1}" class="reorder-item">{{language.l_234}}</div>
      <!-- 销量 -->
      <div @click="selectReorder(2)" :class="{'reorder-active': reorder == 2}" class="reorder-item">{{language.l_235}}<span class="iconfont icon_sort"></span></div>
      <!-- 价格 -->
      <div @click="selectReorder(3)" :class="{'reorder-active': reorder == 3}" class="reorder-item">{{language.l_236}}<span class="iconfont icon_sort"></span></div>
    </div>
    <div v-if="list" class="content">
      <van-list v-if="list.length > 0" @load="onReachBottom" v-model="loading" :finished="finished">
        <div v-for="(item, index) in list" :key="index" @click="toGoodsInfo(item.id)" class="van-hairline--bottom">
          <van-card>
            <template v-slot:thumb>
              <van-image :src="item.pics.length > 0 ? item.pics[0].url : ''" lazy-load fit="contain"/>
            </template>
            <template v-slot:title>
              <p class="content-title van-multi-ellipsis--l2">{{item.title}}</p>
            </template>
            <template v-slot:desc>
              <div class="content-descs">
                <div class="content-desc van-ellipsis">{{item.desc}}</div>
                <div class="content-info">
                  <div class="content-price">$<span class="content-bigSize">{{item.sale_price}}</span></span></div>
                  <!-- 人付款 -->
                  <!-- <div class="content-sales">{{item.sale_volume}}{{language.l_159}}</div> -->
                </div>
              </div>
            </template>
          </van-card>
        </div>
      </van-list>
      <div v-else class="page-bg">
        <van-image src="https://resource.meifanco.com/20190725/ba28554d3d4bf61648d8155ebfdeae48.png"/>
        <!-- 没有相关数据哦  -->
        <p>{{language.l_237}}</p>
      </div>
    </div>
  </div>
</template>

<script>
import {getGoodsList} from '@api'

  export default {
    data () {
      return {
        params: {
          keywords: '',
          cat_id: '', // 分类id
          page: 1,
          page_size: 10
        },
        name: '',
        reorder: 1,
        list: null,
        count: 0,
        loading: false,
        finished: true,
        language: this.$store.getters.language
      }
    },
    activated() {
      if(!this.$route.meta.isUseCache) {
        this._resetData()
        const {query: {keywords, cat_id}} = this.$route;
        if(keywords) {this.params.keywords = keywords}
        if(cat_id) {this.params.cat_id = cat_id}
        this.getData(this.params)
      }
      this.$route.meta.isUseCache = false;
      if(this.$route.query.name) {
        this.name = this.$route.query.name
      }
    },
    methods:{
      _resetData() {
        this.params = {
          keywords: '',
          cat_id: '',
          page: 1,
          page_size: 10
        }
        this.reorder = 1;
        this.sorter = {
          sale_volume: '',
          sale_price: ''
        },
        this.name = '';
      },
      onSearch() { //搜索
        if(this.params.keywords === '') {
          return this.$toast(this.language.l_232)  //请输入搜索内容
        }
        this.params.page = 1;
        this.getData(this.params);
      },
      toGoodsInfo(id) {   //跳转商品详情
        this.$router.push({name: 'GoodsInfo', params: {id}})
      },
      selectReorder(index) { // 筛选
        this.reorder = index;
        this.params.page = 1;
        if(index > 1) {
          const {sale_volume, sale_price} = this.sorter;
          if(index == 2) {
            this.sorter.sale_volume = sale_volume == 'desc' ? 'asc' : 'desc';
            this.sorter.sale_price = '';
          }
          if(index == 3) {
            this.sorter.sale_volume = '';
            this.sorter.sale_price = sale_price == 'desc' ? 'asc' : 'desc';
          }
          this.params.sort = JSON.stringify(this.sorter);
          this.getData(this.params)
        } else {
          this.params.sort = '';
          this.getData(this.params)
        }
      },
      onReachBottom() {  //上拉加载
        const { params, count, list } = this;
        const maxPage = Math.ceil(count / params.page_size); // 最大页码
        this.getData(params, res => {
          this.loading = false;
          this.list = [...list, ...res.data];
          this.params.page++;
          if (this.params.page > maxPage) { 
            this.finished = true;
            this.params.page--;
          }
        })
      },
      storageSearch(keywords) {  //存储搜索记录
        let historyList =  JSON.parse(localStorage.getItem('historyList'));
        if(historyList) {
          const index = historyList.findIndex(item => item == keywords);
          if(index < 0) { // 不存在
            historyList.unshift(keywords);
          } else {  // 存在
            historyList.splice(index, 1);
            historyList.unshift(keywords);
          }
          localStorage.setItem('historyList', JSON.stringify(historyList));
        } else {
          localStorage.setItem('historyList', JSON.stringify([keywords]));
        }
      },
      getData(params, callback) {
        if(params.keywords) {this.storageSearch(params.keywords)}
        getGoodsList(params).then(res => {
          if(callback) return callback(res.data);
            const {data: list, count} = res.data;
            this.finished = true;
            this.list = list;
            this.count = count;
            if(res.data.count > params.page_size) {
              this.finished = false;
              this.params.page++;
            }
          }
        )
      }
    },
    beforeRouteLeave (to, from, next) {
      if(to.name == 'GoodsInfo') {from.meta.isUseCache = true}
      next()
    }
  }
</script>

<style lang='less' scoped>
#searchInfo {
  height: 100%;
  background-color: #fff;
  .leftText {
    max-width: 88px;
    font-size: 14px;
    .grandient_color(linear-gradient(90deg,#F09244,#EC5E2A));
  }
  .reorder {
    position: relative;
    display: flex;
    font-size: 14px;
    color: #666666;
    background-color: #fff;
    z-index: 999;
    .reorder-item {
      flex: 1;
      height: 40px;
      line-height: 40px;
      text-align: center;
      &.reorder-active {
        color: #FD5001;
      }
      .icon_sort {
        font-size: 12px;
        margin-left: 6px;
      }
    }
  }
  .content {
    padding: 0 10px 10px;
    background-color: #fff;
    .safe_padding_bottom();
    .van-card {
      padding: 10px 15px;
      background-color: #fff;
      .van-card__header {
        align-items: center;
        .van-card__thumb {
          width: 100px;
          height: 100px;
          margin-right: 10px;
          .van-image {
            display: block;
            width: 100%;
            height: 100%;
            border-radius: 8px;
            box-shadow: 1px 0px 2px rgba(0,0,0,.1);
            background-color: #fff;
          }
        }
        .van-card__content {
          align-self: flex-start;
          .content-title {
            font-size: 14px;
            margin-top: 10px;
            margin-bottom: 5px;
            font-weight: bold;
            line-height: 1.2;
          }
          .content-descs {
            .content-desc {
              color: #999;
              font-weight: bold;
              font-size: 12px;
            }
            .content-info {
              display: flex;
              align-items: flex-end;
              margin-top: 30px;
              .content-price {
                color: red;
                .content-bigSize {
                  font-size: 14px;
                }
              }
              .content-sales {
                margin-left: 10px;
                color: #999999;
              }
            }
          }
        }
      }
    }
  }
  .page-bg {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 150px;
    .van-image {
      width: 80%;
    }
    p {
      color: #999;
      margin: 20px 0 20px;
    }
  }
}
</style>